var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vxe-table",
    {
      ref: "xTree",
      attrs: {
        border: "none",
        "row-config": { isCurrent: true, isHover: true },
        "column-config": { resizable: true },
        "tree-config": {
          hasChild: "hasChild",
          parentField: "parentId",
          rowField: "teamId",
          expandRowKeys: [],
        },
        data: _vm.copyTableData,
      },
      on: {
        "cell-click": _vm.cellClickEvent,
        "toggle-tree-expand": _vm.toggleExpandChangeEvent,
      },
    },
    _vm._l(_vm.columns, function (record) {
      return _c("vxe-column", {
        key: record.dataIndex,
        attrs: {
          fixed: record.dataIndex == "team" ? "left" : "",
          field: record.dataIndex,
          title: record.title,
          minWidth: record.width,
          "tree-node": record.dataIndex == "team",
          "show-overflow": "",
        },
        scopedSlots: _vm._u(
          [
            record.dataIndex == "team"
              ? {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.hasChild
                        ? _c("div", [
                            _vm._v(
                              "\n        " + _vm._s(row.team) + "\n        "
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  row.children.length
                                    ? "（" + row.children.length + "）"
                                    : ""
                                )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  },
                }
              : record.dataIndex == "user"
              ? {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("div", [
                        row.user && row.user.avatar
                          ? _c("img", {
                              staticStyle: {
                                width: "35px",
                                height: "35px",
                                "border-radius": "50%",
                              },
                              attrs: { src: row.user.avatar, alt: "" },
                            })
                          : _vm._e(),
                        _c("span", { staticStyle: { "margin-left": "5px" } }, [
                          _vm._v(_vm._s(row.user && row.user.userName)),
                        ]),
                      ]),
                    ]
                  },
                }
              : record.dataIndex == "deadline"
              ? {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      !row.hasChild
                        ? _c("div", [
                            _vm._v(
                              "\n        " +
                                _vm._s(row.deadline.substring(0, 16)) +
                                "\n      "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                }
              : record.dataIndex == "deadlineNew"
              ? {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      !row.hasChild
                        ? _c("a-date-picker", {
                            staticClass: "kanban-date",
                            staticStyle: {
                              "font-size": "12px",
                              "min-width": "100px !important",
                            },
                            attrs: {
                              format: "YYYY-MM-DD HH:mm",
                              valueFormat: "YYYY-MM-DD HH:mm",
                              "show-time": { format: "HH:mm" },
                              placeholder: "请选择截止日期",
                              defaultValue: row.deadlineNew,
                            },
                            on: { change: (e) => _vm.datePickerChange(e, row) },
                          })
                        : _vm._e(),
                    ]
                  },
                }
              : null,
          ],
          null,
          true
        ),
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }