<template>
  <vxe-table
    border="none"
    ref="xTree"
    :row-config="{ isCurrent: true, isHover: true }"
    :column-config="{ resizable: true }"
    @cell-click="cellClickEvent"
    @toggle-tree-expand="toggleExpandChangeEvent"
    :tree-config="{
      hasChild: 'hasChild',
      parentField: 'parentId',
      rowField: 'teamId',
      expandRowKeys: []
    }"
    :data="copyTableData"
  >
    <vxe-column
      v-for="record in columns"
      :key="record.dataIndex"
      :fixed="record.dataIndex == 'team' ? 'left' : ''"
      :field="record.dataIndex"
      :title="record.title"
      :minWidth="record.width"
      :tree-node="record.dataIndex == 'team'"
      show-overflow
    >
      <template v-if="record.dataIndex == 'team'" v-slot="{ row }">
        <div v-if="row.hasChild">
          {{ row.team }}
          <span>{{ row.children.length ? '（' + row.children.length + '）' : '' }}</span>
        </div>
      </template>
      <template v-else-if="record.dataIndex == 'user'" v-slot="{ row }">
        <div>
          <img
            v-if="row.user && row.user.avatar"
            :src="row.user.avatar"
            style="width:35px;height:35px;border-radius: 50%;"
            alt=""
          />
          <span style="margin-left:5px;">{{ row.user && row.user.userName }}</span>
        </div>
      </template>
      <template v-else-if="record.dataIndex == 'deadline'" v-slot="{ row }">
        <div v-if="!row.hasChild">
          {{ row.deadline.substring(0, 16) }}
        </div>
      </template>
      <template v-else-if="record.dataIndex == 'deadlineNew'" v-slot="{ row }">
        <a-date-picker
          v-if="!row.hasChild"
          class="kanban-date"
          style="font-size:12px;min-width:100px !important;"
          format="YYYY-MM-DD HH:mm"
          valueFormat="YYYY-MM-DD HH:mm"
          :show-time="{ format: 'HH:mm' }"
          @change='(e)=>datePickerChange(e,row)'
          placeholder="请选择截止日期"
          :defaultValue="row.deadlineNew"
        />
      </template>
    </vxe-column>
  </vxe-table>
</template>

<script>
export default {
  props: {
    scheduleTasks: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      copyTableData:[],
      tableData: [],
      columns: [
        {
          title: '章节序号',
          width: 140,
          dataIndex: 'team',
          checked: true
        },
        {
          title: '阶段',
          width: 120,
          dataIndex: 'stageName',
          checked: true
        },
        {
          title: '人员',
          width: 120,
          dataIndex: 'user',
          checked: true
        },
        {
          title: '当前截止日',
          width: 200,
          dataIndex: 'deadline',
          checked: true
        },
        {
          title: '修改后的截止日',
          width: 200,
          dataIndex: 'deadlineNew',
          checked: true
        }
      ]
    }
  },
  watch: {
    scheduleTasks: {
      handler(newValue, oldValue) {
        //这里的第一个参数是改变后的值，第二个参数是原来的值
        this.tableData = []
        this.$nextTick(() => {
          this.scheduleTasks.forEach(item => {
            if (this.tableData.every(i => i.teamId != item.chapterOrder)) {
              this.tableData.push({
                value: item.chapterOrder,
                team: item.chapterOrder,
                teamId: item.chapterOrder,
                hasChild: true,
                children: []
              })
            }
          })
          this.scheduleTasks.forEach(item => {
            this.tableData.forEach(i => {
              if (i.teamId == item.chapterOrder) {
                item.teamId = item.id
                i.children.push(item)
              }
            })
          })
        })
      },
      deep: true
    }
  },
  methods: {
    datePickerChange(e,row){
      this.tableData.forEach(item=>{
        item.children.forEach(i=>{
          if(i.id==row.id){
            i.deadlineNew=e
          }
        })
      })
    },
    getTableData() {
      this.scheduleTasks.forEach(item => {
        if (this.tableData.every(i => i.teamId != item.chapterOrder)) {
          this.tableData.push({
            value: item.chapterOrder,
            team: item.chapterOrder,
            teamId: item.chapterOrder,
            hasChild: true,
            children: []
          })
        }
      })
      this.scheduleTasks.forEach(item => {
        this.tableData.forEach(i => {
          if (i.teamId == item.chapterOrder) {
            item.teamId = item.id
            i.children.push(item)
          }
        })
      })
      this.copyTableData = JSON.parse(JSON.stringify(this.tableData))
    },
    async cellClickEvent(e) {
      if (e.row.expend) {
        e.row.expend = false
        this.$refs.xTree.setTreeExpand(e.row, false)
      } else {
        e.row.expend = true
        this.$refs.xTree.setTreeExpand(e.row, true)
      }
      if (this.$refs.xTree) this.$refs.xTree.updateData()
      return
    },
    toggleExpandChangeEvent({ row, expanded }) {
      this.$refs.xTree.toggleTreeExpand(row)
      if (this.$refs.xTree) this.$refs.xTree.updateData()
      return
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .vxe-body--row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25) !important;
}
</style>
